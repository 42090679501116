import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { ISlice, IOnOffType, IContactBlocks } from "../../types/"
import { IBlockTheme } from "../../types/prismic/ContactBlocks"

// Styling
import { mq } from "../../styles/utils/mq"
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"

// Utils
import PageContext from "../../utils/context/PageContext"

// Components
import { renderSlice } from "../../components/helpers/slice"
import { Row, Col } from "../../components/layout/Grid"
import SEO from "../../components/layout/SEO"
import Container from "../../components/layout/Container"
import Divider from "../../components/layout/Divider"
import Link from "../../components/generic/Link"
import AnimatedScrollWrapper from "../../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../../components/animation/AnimatedTextScrollWrapper"
import ContactBlocks from "../../components/ContactBlocks"
import SocialBlockComponent from "../../components/SocialBlock"

// Config
import { config as pageTransitionsConfig } from "../../components/pageTransitions/config"

interface IProps {
  data: {
    metaTitle?: string
    metaDescription?: string
    title: string[]
    headline?: string
    vacancyType: string
    publicationDate: string
    vacancyLink: {
      url: string
    }
  }
  contactBlocksData: IContactBlocks
  slices: ISlice[]
}

const VacancyTemplate = ({ data, contactBlocksData, slices }: IProps) => {
  const page = useContext(PageContext)
  const { title, headline, publicationDate, vacancyType } = data

  const contactBlocksOptions = {
    ...contactBlocksData,
    detailsBlockTheme: "accent" as IBlockTheme,
    contactBlockTheme: "dark" as IBlockTheme,
  }

  useEffect(() => {
    page.updateColor(colors.white)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO
        title={`${data.metaTitle || data.title} - Vacancy`}
        description={data.metaDescription}
      />

      <Header>
        <Container>
          <Row>
            {publicationDate && (
              <PublicationDate>
                <AnimatedScrollWrapper>
                  &mdash; {publicationDate}
                </AnimatedScrollWrapper>
              </PublicationDate>
            )}

            <Col m={8} mOffset={2}>
              {title && title.length && (
                <Title>
                  <AnimatedTextScrollWrapper
                    text={title}
                    textStyles={textStyles.headingL}
                  />
                </Title>
              )}

              {headline && (
                <Headline>
                  <AnimatedScrollWrapper>{headline}</AnimatedScrollWrapper>
                </Headline>
              )}
            </Col>

            <Col m={3} mOffset={1}>
              {vacancyType && (
                <PublicationMetadata>
                  <AnimatedScrollWrapper>
                    &mdash; {vacancyType}
                  </AnimatedScrollWrapper>
                </PublicationMetadata>
              )}
            </Col>
          </Row>

          <Row>
            <Col m={12} mOffset={2}>
              <AnimatedScrollWrapper>
                <Divider color={colors.black} />
              </AnimatedScrollWrapper>
            </Col>
          </Row>
        </Container>
      </Header>

      {data.vacancyLink && data.vacancyLink.url && (
        <Row>
          <Col xxs={6} xxsOffset={1} m={12} mOffset={2}>
            <TopVacancyLinkWrapper>
              <Link
                type="external"
                url={data.vacancyLink.url}
                title="Apply here"
                color={colors.black}
              />
            </TopVacancyLinkWrapper>

            <AnimatedScrollWrapper>
              <Divider color={colors.black} />
            </AnimatedScrollWrapper>
          </Col>
        </Row>
      )}

      {slices && slices.length && (
        <Content>
          {slices.map((slice: ISlice) => {
            const hasSpacing = slice.data.hasSpacing

            const props = {
              // Assign a default
              isSpaced: "on" as IOnOffType,
              // Override if condition passes
              ...(hasSpacing === "off" && {
                isSpaced: slice.data.hasSpacing,
              }),
            }

            return (
              <Section key={slice.id} {...props}>
                {renderSlice(slice)}
              </Section>
            )
          })}
        </Content>
      )}

      {data.vacancyLink && data.vacancyLink.url && (
        <Row>
          <Col xxs={6} xxsOffset={1} m={13} mOffset={2}>
            <AnimatedScrollWrapper>
              <Divider color={colors.black} />
            </AnimatedScrollWrapper>

            <BottomVacancyLinkWrapper>
              <AnimatedScrollWrapper>
                <Link
                  type="external"
                  url={data.vacancyLink.url}
                  title="Apply today"
                  color={colors.black}
                />
              </AnimatedScrollWrapper>
            </BottomVacancyLinkWrapper>
          </Col>
        </Row>
      )}

      <ContactBlocks {...contactBlocksOptions} />

      <SocialBlock theme="light" />
    </Wrapper>
  )
}

// Generic styles
const Section = styled.section<{ isSpaced?: IOnOffType }>`
  margin-bottom: var(--base-column-size);

  ${mq.from.L`
    margin-bottom: ${(props: { isSpaced?: IOnOffType }) =>
      props.isSpaced === "on" ? "var(--base-column-size)" : "0"};
  `}
`

const TopVacancyLinkWrapper = styled.div`
  margin-bottom: var(--base-column-size);
`

const BottomVacancyLinkWrapper = styled.div`
  margin-top: var(--base-column-size);
  margin-bottom: var(--base-column-size);
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.black};
`

const Content = styled.div`
  padding-top: var(--base-column-size);
`

// Header
const Header = styled.header`
  position: relative;
  margin-bottom: var(--base-column-size);
`

const PublicationDate = styled.div`
  margin-bottom: 8px;

  ${mq.from.M`
    position: absolute;
    top: 8px;
    left: var(--base-column-size);
    width: 25vh;
    height: var(--base-column-size);
    margin-bottom: 0;
    text-align: right;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  `}
`

const Title = styled.div`
  margin-bottom: calc(var(--base-column-size) / 4);
`

const Headline = styled.h3`
  ${textStyles.headingS};
  margin-bottom: calc(var(--base-column-size) / 4);
  color: ${colors.accent1};
`

const PublicationMetadata = styled.div`
  margin-bottom: 16px;

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const SocialBlock = styled(SocialBlockComponent)`
  background-color: ${colors.lGrey};
`

export default VacancyTemplate
