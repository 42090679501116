import React from "react"
import { graphql } from "gatsby"

// Utils
import {
  formatPrismicData,
  formatContactBlocks,
  getTextLines,
} from "../../utils/helpers/prismicHelpers"

// Components
import VacancyTemplate from "./vacancyTemplateView"

const VacancyTemplateContainer = ({ data }: any) => {
  if (!data.vacancy) {
    return
  }

  let slices
  const rawVacancyData = data.vacancy.data
  const rawFooterBlocksData = data.vacancyFooterBlocks.data

  const formattedVacancyData = {
    metaTitle: rawVacancyData.metaTitle,
    metaDescription: rawVacancyData.metaDescription,
    title: getTextLines(rawVacancyData.title.raw),
    headline: rawVacancyData.headline,
    vacancyType: rawVacancyData.vacancy_type,
    publicationDate: rawVacancyData.publication_date,
    vacancyLink: rawVacancyData.vacancy_link,
  }

  const formattedContactBlocksData = formatContactBlocks(rawFooterBlocksData)

  if (rawVacancyData.slices) {
    slices = rawVacancyData.slices
      .filter((slice: any) => slice.type && slice)
      .map((slice: any) => formatPrismicData(slice))
  }

  return (
    <VacancyTemplate
      data={formattedVacancyData}
      contactBlocksData={formattedContactBlocksData}
      slices={slices}
    />
  )
}

export const query = graphql`
  query loadPrismicVacancy($slug: String!) {
    vacancy: prismicVacancy(uid: { eq: $slug }) {
      id
      slug: uid
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        title {
          raw {
            text
          }
        }
        headline
        vacancy_type
        publication_date(formatString: "DD MMMM YYYY")
        vacancy_link {
          url
        }
        slices: body {
          ... on PrismicVacancyBodyTextBlock {
            type: slice_type
            id
            primary {
              text_block_title
              text_block_text {
                html
              }
              text_block_alignment
            }
          }
          ... on PrismicVacancyBodyImage {
            type: slice_type
            id
            primary {
              image {
                alt
                url
              }
              image_size
            }
          }
        }
      }
    }
    vacancyFooterBlocks: prismicVacancyFooterBlocks {
      data {
        block_title_1 {
          raw {
            text
          }
        }
        block_copy_1 {
          html
        }
        block_link_name_1
        block_title_2 {
          raw {
            text
          }
        }
        block_copy_2 {
          html
        }
        block_link_name_2
      }
    }
  }
`

export default VacancyTemplateContainer
